import {HttpInterceptorFn} from '@angular/common/http'
import {inject} from '@angular/core'
import {DesignService} from '../../design/design.service'

export const COMPANY_ID_HEADER = 'X-Company-Id'

export const headerCompanyIdInterceptor: HttpInterceptorFn = (req, next) => {
    const company_id: string = inject(DesignService).companyId
    const modifiedReq = req.clone({
        headers: req.headers.set(COMPANY_ID_HEADER, company_id)
    })

    return next(modifiedReq)
}
