import {Routes} from '@angular/router'
import {AuthComponent} from './auth/auth.component'
import {AuthenticationGuard} from './auth/authentication.guard'
import {UnprotectedLayoutComponent} from './unprotected/unprotected-layout.component'

export const appRoutes: Routes = [
    {
        path: 'auth', component: AuthComponent,
        loadChildren: () => import('./auth/auth.routes')
    },
    {path: 'app', loadChildren: () => import('./pages/pages.routes'), canActivate: [AuthenticationGuard]},
    {
        path: '',
        component: UnprotectedLayoutComponent,
        children: [
            {
                path: 'broker',
                loadComponent: () => import('./unprotected/unprotected-commission/unprotected-commission.component').then(m => m.UnprotectedCommissionComponent)
            },
            {
                path: 'client',
                loadComponent: () => import('./unprotected/unprotected-client/unprotected-client.component').then(m => m.UnprotectedClientComponent)
            },
            {
                path: 'invoice',
                loadComponent: () => import('./unprotected/invoice/invoice.component').then(m => m.InvoiceComponent)
            },
            {
                path: 'confirm-invitation',
                loadComponent: () => import('./unprotected/confirm-invitation/confirm-invitation.component').then(m => m.ConfirmInvitationComponent)
            },
            {
                path: 'reset-password',
                loadComponent: () => import('./unprotected/reset-password/reset-password.component').then(m => m.ResetPasswordComponent)
            },
            {
                path: 'thank-you',
                loadComponent: () => import('./unprotected/thank-you-page/thank-you-page.component').then(m => m.ThankYouPageComponent)
            },
            {
                path: '',
                redirectTo: 'app',
                pathMatch: 'full'
            }
        ]
    },
    {
        path: '',
        redirectTo: 'app',
        pathMatch: 'full'
    },
    {path: '**', redirectTo: 'app'},
]
