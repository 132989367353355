import {AuthService} from './auth/auth.service'
import {inject} from '@angular/core'
import {firstValueFrom} from 'rxjs'
import {DesignService} from './@core/design/design.service'
import {Title} from '@angular/platform-browser'
import {Router} from '@angular/router'

export function appInitializer() {
    const authService = inject(AuthService)
    const designService = inject(DesignService)
    const titleService: Title = inject(Title)
    const router: Router = inject(Router)
    const root: '/' = '/'

    return () => {
        // Return a promise that resolves when initialization is complete
        return new Promise<void>(async (resolve) => {
            try {
                // Initialize design settings (will fetch only if not cached)
                const designSettings = await firstValueFrom(designService.initializeDesignSettings())

                // Set the title based on the design service name
                if (designSettings?.name) {
                    const title = `${designSettings.name} | Member Manager`
                    titleService.setTitle(title)
                }

                if (designSettings.company_prefix) {
                    const link: HTMLLinkElement = document.querySelector('link[rel*=\'icon\']') || document.createElement('link')
                    link.type = 'image/png'
                    link.rel = 'shortcut icon'
                    link.href = `/assets/fav-icons/${designSettings.company_prefix}.png`
                    document.getElementsByTagName('head')[0].appendChild(link)
                }

                // If we're on the app page, fetch the user data
                if (window.location.pathname === root || window.location.pathname.startsWith('/app')) {
                    // Check if we have a token before attempting to fetch user
                    const token = await firstValueFrom(authService.token$)

                    if (token) {
                        // If we have a token, fetch the user data
                        await firstValueFrom(authService.fetchMe())
                        if (window.location.pathname === root) {
                            router.navigate(['/app'])
                        }
                    } else {
                        const redirectUrl = window.location.pathname === root ? '/app' : encodeURIComponent(window.location.pathname)
                        await router.navigate(['/auth'], {
                            replaceUrl: true,
                            queryParams: {redirectUrl}
                        })
                    }
                }

                resolve()
            } catch (error) {
                // If there's an error, we should still resolve but log the error
                console.error('Error during app initialization:', error)
                resolve()
            }
        })
    }
}
