import {Component} from '@angular/core'
import {RouterOutlet} from '@angular/router'
import {NavbarComponent} from '../shared/components/navbar/navbar.component'
import {MatDrawerContainer, MatDrawerContent} from '@angular/material/sidenav'

@Component({
    selector: 'app-unprotected-layout',
    standalone: true,
    imports: [RouterOutlet, MatDrawerContainer,MatDrawerContent, NavbarComponent, NavbarComponent],
    template: `
        <div class="app-layout-container">
            <app-navbar [useWithAuth]="false" />
            <mat-drawer-container autosize="true">
                <mat-drawer-content #drawerContent>
                    <div class="sidenav-scroll-wrapper">
                        <router-outlet />
                    </div>
                </mat-drawer-content>
            </mat-drawer-container>
        </div>
    `
})
export class UnprotectedLayoutComponent {
}
